export function getFieldValues(content, tname) {
    if (content != null && Object.prototype.hasOwnProperty.call(content, 'fieldTypes') && content.fieldTypes != null) {
        /*find a specific field*/
        for (var i = 0; i < content.fieldTypes.length; i++) {
            var field = content.fieldTypes[i];
            if (Object.prototype.hasOwnProperty.call(field, 'technical_name') && field.technical_name == tname) {
                if (field.value != null && Array.isArray(field.value) && field.value.length == 1) {
                    return field.value[0];
                }
                return field.value;
            }
        }
    }
    return null;
}

export function parseTravelTime(timeInMilliSeconds) {
    var hours = null;
    var minutes = null;
    var totalTime = null;
    var timeInMinutes = timeInMilliSeconds / 60000;
    if (timeInMinutes > 60) {
        hours = Math.round(timeInMinutes / 60);
        minutes = timeInMinutes - (hours * 60);
        if (minutes <= 0) {
            totalTime = hours + ' h';
        }
        else {
            totalTime = hours + ' h ' + Math.round(minutes) + ' min';
        }
    }
    else {
        minutes = timeInMinutes;
        totalTime = Math.round(minutes) + ' min';
    }
    return totalTime;
}

/**
* decode with try-catch
*/
export function json_decode(elem) {
    try {
        return JSON.parse(elem);
    } catch (e) {
        return elem;
    }
}

export function getWeekday(wday) {
    var day = "";
    switch (wday) {
        case 1:
            day = "Montag";
            break;
        case 2:
            day = "Dienstag";
            break;
        case 3:
            day = "Mittwoch";
            break;
        case 4:
            day = "Donnerstag";
            break;
        case 5:
            day = "Freitag";
            break;
        case 6:
            day = "Samstag";
            break;
        case 7:
            day = "Sonntag";
            break;
        case 0:
            day = "Sonntag";
            break;
    }
    return day;
}

export function getMonth(month) {
    var amonth = "";
    switch (month) {
        case 1:
            amonth = "Januar";
            break;
        case 2:
            amonth = "Februar";
            break;
        case 3:
            amonth = "März";
            break;
        case 4:
            amonth = "April";
            break;
        case 5:
            amonth = "Mai";
            break;
        case 6:
            amonth = "Juni";
            break;
        case 7:
            amonth = "Juli";
            break;
        case 8:
            amonth = "August";
            break;
        case 9:
            amonth = "September";
            break;
        case 10:
            amonth = "Oktober";
            break;
        case 11:
            amonth = "November";
            break;
        case 12:
            amonth = "Dezember";
            break;
    }
    return amonth;
}

export function formatTime(time) {
    if (time != null && time != "") {
        var timeParts = time.split(':');
        return timeParts[0] + ':' + timeParts[1];
    }
    return "";
}

export function formatDate(date) {
    if (date != null && date != "") {
        var dateParts = date.split('-');
        return dateParts[2] + '.' + dateParts[1] + '.' + dateParts[0];
    }
    return "";
}

export function getCurrentDate() {
    var currentdate = new Date();
    var date = currentdate.getDate() + "."
        + (currentdate.getMonth() + 1) + "."
        + currentdate.getFullYear()
    return date;
}


export function getCurrentSystemDate() {
    var currentdate = new Date();
    var date = currentdate.toISOString().split('T')[0];
    return date;
}


export function getDateFromDateParam(dateParam) {
    var currentdate = new Date(dateParam);
    var date = currentdate.toISOString().split('T')[0];
    return date;
}

export function getCurrentTime() {
    var currentdate = new Date();
    var time = currentdate.getHours() + ":"
        + currentdate.getMinutes() + ":"
        + currentdate.getSeconds();
    return time;
}

export function getCurrentWeekDay() {
    var currentdate = new Date();
    var wday = currentdate.getDay();
    /*we need to add +1 wo wday for our format*/
    return wday++;
}

export function isAppointmentNow(appointment) {
    var currentDate = getCurrentSystemDate();
    var currentTime = getCurrentTime();
    var currentWeekDay = getCurrentWeekDay();
    var isCurrentDate = false;
    var isCurrentTime = false;
    var isCurrentWeekDay = false;

    /*check if the appointment has a date and if that date is today*/
    if (appointment.date != null) {
        var today = Date.parse(currentDate);
        var appoDate = Date.parse(appointment.date);
        isCurrentDate = today == appoDate ? true : false;
    }

    if (appointment.wday != null) {
        isCurrentWeekDay = appointment.wday == currentWeekDay ? true : false;
    }

    /*check if we have a hit for current date or weekday - only then do we have to look for the time*/
    if (isCurrentDate || isCurrentWeekDay) {
        if (appointment.times != null && appointment.times.length > 0) {
            for (var i = 0; i < appointment.times.length; i++) {
                var time = appointment.times[i];
                isCurrentTime = checkCurrentTimeBetween(time.time_from, time.time_to, currentTime);
                if (isCurrentTime) {
                    /*look no more ...*/
                    break;
                }
            }
        }
    }

    /*do we have a hit?*/
    if ((isCurrentDate || isCurrentWeekDay) && isCurrentTime) {
        return true;
    }

    return false;

}

export function isOneOfTheAppointmentsNow(appointments) {

    var exceptions = null;
    var isInExceptionRange = false;
    var currentDate = getCurrentSystemDate();

    /*check if there are exceptions*/
    if (Object.prototype.hasOwnProperty.call(appointments, 'exceptions') && appointments.exceptions != null) {
        exceptions = appointments.exceptions;
        /*so let's check if the current date is in exceptions*/
        for (var l = 0; l < exceptions.length; l++) {
            var exception = exceptions[l];
            isInExceptionRange = checkCurrentDateBetween(exception.start_date, exception.end_date, currentDate);
            if (isInExceptionRange) {
                return false;
            }
        }
    }


    if (appointments != null && Object.prototype.hasOwnProperty.call(appointments, 'days')) {
        for (var i = 0; i < appointments.days.length; i++) {
            var appointment = appointments.days[i];
            if (isAppointmentNow(appointment)) {
                return true;
            }
        }
    }
    return false;
}

export function checkCurrentTimeBetween(start, end, current) {
    var Time = function (timeString) {
        var t = timeString.split(":");
        this.hour = parseInt(t[0]);
        this.minutes = parseInt(t[1]);
        this.isBiggerThan = function (other) {
            return (this.hour > other.hour) || (this.hour === other.hour) && (this.minutes > other.minutes);
        };
    }

    var timeIsBetween = function (start, end, check) {
        return (start.hour <= end.hour) ? check.isBiggerThan(start) && !check.isBiggerThan(end)
            : (check.isBiggerThan(start) && check.isBiggerThan(end)) || (!check.isBiggerThan(start) && !check.isBiggerThan(end));
    }

    var openTime = new Time(formatTime(start));
    var closeTime = new Time(formatTime(end));
    var checkTime = new Time(formatTime(current));

    return timeIsBetween(openTime, closeTime, checkTime);
}

export function checkCurrentDateBetween(start, end, current) {
    start = new Date(start).toISOString().split('T')[0];
    end = new Date(end).toISOString().split('T')[0];
    current = new Date(current).toISOString().split('T')[0];

    return (current < start || current > end) ? false : true;
}

export function checkCurrentTimeBefore(start, current) {
    var Time = function (timeString) {
        var t = timeString.split(":");
        this.hour = parseInt(t[0]);
        this.minutes = parseInt(t[1]);
        this.isBiggerThan = function (other) {
            return (this.hour > other.hour) || (this.hour === other.hour) && (this.minutes > other.minutes);
        };
    }

    var timeIsBefore = function (start, check) {
        return check.isBiggerThan(start) ? false : true;
    }

    var openTime = new Time(formatTime(start));
    var checkTime = new Time(formatTime(current));

    return timeIsBefore(openTime, checkTime);
}

export function getNextAppointment(appointments, date = null) {
    if (appointments != null && Object.prototype.hasOwnProperty.call(appointments, 'days') && appointments.days != null && appointments.days.length > 0) {
        var exceptions = null;
        var isInExceptionRange = false;
        var currentTime = getCurrentTime();
        var currentDate = getCurrentSystemDate();
        var currentWeekDay = getCurrentWeekDay();

        /*if we need the appointments for another date ...*/
        if (date != null) {
            var theDate = new Date(date);
            currentDate = getDateFromDateParam(date);
            currentWeekDay = theDate.getDay();
            currentWeekDay = currentWeekDay;
            currentTime = "00:00:00";
        }

        // Ensure currentDate is in a consistent format
        currentDate = new Date(currentDate).toISOString().split('T')[0];

        if (currentWeekDay == 0) {
            currentWeekDay = 7;
        }

        /*check if there are exceptions*/
        if (Object.prototype.hasOwnProperty.call(appointments, 'exceptions') && appointments.exceptions != null) {
            exceptions = appointments.exceptions;
            /*so let's check if the current date is in exceptions*/
            for (var l = 0; l < exceptions.length; l++) {
                var exception = exceptions[l];
                isInExceptionRange = checkCurrentDateBetween(exception.start_date, exception.end_date, currentDate);
                if (isInExceptionRange) {
                    /**if the currentDate is within an exception-range we'll manipulate the current date and the currentWeekday
                    to find the next valid Date after the exception-range*/
                    currentDate = new Date(exception.end_date).toISOString().split('T')[0];
                    var aDate = new Date(currentDate);
                    currentWeekDay = aDate.getDay();
                    /*cancel for if isInExceptionRange becomes true*/
                    break;
                }
            }
        }

        /*the actual appointments*/
        appointments = appointments.days;

        /*new variables for the next Date*/
        var nextTime = null;
        var next = { date: null, wday: null, time: null };

        var checkAppoTimes = function (appo) {
            for (var j = 0; j < appo.times.length; j++) {
                var time = appo.times[j];
                if (checkCurrentTimeBefore(time.time_from, currentTime)) {
                    return time;
                }
            }
            return null;
        }

        for (var i = 0; i < appointments.length; i++) {
            var appointment = appointments[i];

            if (appointment.date != null) {
                /*let's check if the appointment is today*/
                var today = Date.parse(currentDate);
                var appoDate = Date.parse(appointment.date);

                if (today == appoDate) {
                    nextTime = checkAppoTimes(appointment);
                    /*check if we found a next-time*/
                    if (nextTime != null) {
                        /*stop the for-loop - we have a winner*/
                        next.date = appointment.date;
                        next.time = nextTime;
                        return next;
                    }
                }

                /*check for the next appointment*/
                if (today < appoDate) {
                    nextTime = appointment.times != null && appointment.times.length > 0 ? appointment.times[0] : null;
                    /*check if we found a next-time*/
                    if (nextTime != null) {
                        /*stop the for-loop - we have a winner*/
                        next.date = appointment.date;
                        next.time = nextTime;
                        return next;
                    }
                }
            }

            else if (appointment.date == null && appointment.wday != null) {

                /*bloddy americans with their stupid week-format. The first day of the week is not sunday but monday. Sunday is the last day of the week!!!*/
                if (appointment.wday == 0) {
                    appointment.wday = 7;
                }

                if (appointment.wday == currentWeekDay) {
                    nextTime = checkAppoTimes(appointment);
                    /*check if we found a next-time*/
                    if (nextTime != null) {
                        /*stop the for-loop - we have a winner*/
                        next.wday = appointment.wday;
                        next.time = nextTime;
                        return next;
                    }
                }

                /*check for the next appointment*/
                if (appointment.wday > currentWeekDay) {
                    nextTime = appointment.times != null && appointment.times.length > 0 ? appointment.times[0] : null;
                    /*check if we found a next-time*/
                    if (nextTime != null) {
                        /*stop the for-loop - we have a winner*/
                        next.wday = appointment.wday;
                        next.time = nextTime;
                        return next;
                    }
                }
            }
        }

        /**now let's see we have gone through all the appointments and haven't found a match if we're here -
        * that could mean that we are at the end of a week or there are no more new appointments. So let's look at the first appointment and check if we could use that
        */
        if (appointments[0].wday != null) {
            appointment = appointments[0];
            nextTime = appointment.times != null && appointment.times.length > 0 ? appointment.times[0] : null;
            /*check if we found a next-time*/
            if (nextTime != null) {
                /*stop the for-loop - we have a winner*/
                next.date = appointment.date;
                next.wday = appointment.wday;
                next.time = nextTime;
                return next;
            }
        }

    }
    return null;
}

export function smartSubStr(str, len) {
    var temp = str.substr(0, len);
    if (temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
        temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')));
    }
    return temp;
}

export function showLoader(loader) {
    loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        color: "#007BFF",
        loader: 'dots',
        opacity: 0.7,
        height: 128,
        width: 128,
        onCancel: this.onCancel,
    });
    return loader;
}


export function hideLoader(loader) {
    loader.hide();
    return loader;
}


/**
 * sorts a given array of objects case-insensitive alphabetically and considering a certain locale  
 */
export function sortByProperty(objects, property, langcode) {
    objects.sort(function (a, b) {
        // Handle cases where the property might be undefined or null
        const aValue = (a[property] ? a[property].toLowerCase() : '') || '';
        const bValue = (b[property] ? b[property].toLowerCase() : '') || '';
        return aValue.localeCompare(bValue, langcode);
    });
    return objects;
}

/**
 * get a placeholder image if no other image is given
 */
export function getPlaceholderImage(category = null) {
    /*we pack all the images in an array so we can return a random one*/
    var images = [
        "/assets/categories/bildungwissenschaft_default.png",
        "/assets/categories/kunstgalerie_default.png",
        "/assets/categories/musiktanz_default.png",
        "/assets/categories/cafesbarclub_default.png",
        "/assets/categories/filmmedien_default.png",
        "/assets/categories/kulturkreativraum_default.png",
        "/assets/categories/museumausstellung_default.png",
        "/assets/categories/religion_default.png",
        "/assets/categories/theateroper_default.png",
        "/assets/categories/lighthouse.png",
    ];

    var image = null;

    /*if a category is given we'll look for an image matching that category*/
    if (category != null) {
        switch (category) {
            case "Bildung & Wissenschaft":
                image = images[0];
                break;
            case "Kunst & Galerien":
                image = images[1];
                break;
            case "Musik & Tanz":
                image = images[2];
                break;
            case "Cafés - Bars & Clubs":
                image = images[3];
                break;
            case "Film & Medien":
                image = images[4];
                break;
            case "Kultur- & Kreativräume":
                image = images[5];
                break;
            case "Museen & Stadtgeschichte":
                image = images[6];
                break;
            case "Religionskultur":
                image = images[7];
                break;
            case "Theater & Oper":
                image = images[8];
                break;
            case "Sehenswürdigkeiten":
                image = images[9];
                break;
            default:
                image = images[Math.floor(Math.random() * images.length)];
                break;
        }
    }

    if (image == null) {
        image = images[Math.floor(Math.random() * images.length)];
    }

    return image;
}

/**
* Extract the allowedFieldValues from a FieldTypeResource
*/
export function getAllowedValuesFromFieldTypes(data) {
    if (Object.prototype.hasOwnProperty.call(data, 'field_type_values') && data.field_type_values != null) {
        /*we'll need to extract the actual values from that array*/
        var allowedValues = [];
        for (var i = 0; i < data.field_type_values.length; i++) {
            var fTypeValueId = data.field_type_values[i].id;
            var value = data.field_type_values[i].allowed_value;
            if (value != null && value != "") {
                allowedValues.push({ 'label': value, 'value': value, 'id': fTypeValueId });
            }
        }
        return allowedValues;
    }
    return null;
}

export function mapOrder(array, order, key) {
    var map = new Map();
    var index = 0;
    var tmp;
    if (!array || !order || array.length !== order.length)
        return array;
    array.forEach(function (it) {
        map.set(it[key], index++);
    });
    order.forEach(function (it) {
        if (map.get(it) === undefined) return array;
    });
    index--;
    for (; index >= 0; index--) {
        if (array[index][key] !== order[index]) {
            tmp = array[index];
            array[index] = array[map.get(order[index])];
            array[map.get(order[index])] = tmp;
            map.set(tmp[key], map.get(order[index]));
        }
    }
    return array;
}


/**
 * checks if a varialbe has a usefull value  
 */
export function isset(variable) {
    if (variable !== null && variable !== undefined && variable !== '') {

        /*check if variable is string*/
        if (typeof variable === 'string') {
            if (variable.trim() !== '') {
                return true;
            }
            else {
                return false;
            }
        }

        return true;
    }

    return false;
}