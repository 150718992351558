<template>
	<div class="marker-institution-content">
		<a href="#" @click.prevent="scrollToStation(content.id)">
			<img class="logo" v-if="logo && false" :src="logo" />

			<h6 class="title mt10">{{ title }}</h6>

			<div class="card-detail__features mt10">

				<div v-if="adresse" class="location">
					<i class="material-icons material-icons-round mr5" aria-hidden="true">place</i>
					<span>{{ adresse }}</span>
				</div>

			</div>


			<a href="#" @click.prevent="scrollToStation(content.id)" class="btn btn-sm mt20 mb10 btn-focus-design">Mehr
				Infos</a>
		</a>
	</div>
</template>
<script>

import { getFieldValues, json_decode } from '@/utils/helpers';

export default {
	name: "MarkerSehenswuerdigkeitContent",
	props: {
		content: {
			type: Object,
			default: null,
			required: true
		}
	},
	data() {
		return {
		};
	},
	watch: {
		content: {
			handler(val) {
				if (this.content.highlight == true) {
					this.$refs.focusPopup.focus();
				}
			},
			deep: true
		}
	},
	computed: {
		institutionOrAttraction() {
			if (this.content !== null) {
				if (this.content.content_type_name == 'sehenswrdigkeit') {
					return { name: 'Attraktion', params: { id: this.content.id } };
				}
				return { name: 'Anbieterprofil', params: { id: this.content.institution_id } };
			}
			return null;
		},
		title() {
			if (this.content != null) {
				var title = this.content.title;
				return title.length > 30 ? title.substring(0, 30) + ' ...' : title;
			}
			return "";
		},
		logo() {
			var logo = getFieldValues(this.content, 'titelbild');
			/*for the old data we've imported we'll need to add a leading /*/
			if (logo != null) {
				logo = json_decode(logo);
				if (typeof logo === "object") {
					return this.$backendUrl + logo.path;
				}
				else {
					logo = logo.charAt(0) != "/" ? "/" + logo : logo;
					return this.$backendUrl + logo;
				}
			}
			return null;
		},
		addressObj() {
			return getFieldValues(this.content, 'adresse');
		},
		adresse() {
			if (this.addressObj != null && (this.addressObj.street !== null && this.addressObj.zipcode !== null && this.addressObj.city !== null)) {
				return this.addressObj.street + ', ' + this.addressObj.zipcode + ' ' + this.addressObj.city;
			}
			else if (this.addressObj != null && (this.addressObj.zipcode !== null && this.addressObj.city !== null)) {
				return this.addressObj.zipcode + ' ' + this.addressObj.city;

			}
			return '';
		},
	},
	methods: {
		json_decode,
		scrollToStation(stationId) {
			const element = document.getElementById(`station-${stationId}`);
			if (element) {
				const offset = 100;
				const elementPosition = element.getBoundingClientRect().top;
				const offsetPosition = elementPosition + window.pageYOffset - offset;

				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth'
				});
			}
		},
	},
}

</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.show-more-btn {
	padding: 8px;
	text-align: center;
	color: $primary;
	border: 1px solid $primary;
	cursor: pointer;
}

.marker-institution-content {
	text-align: center;

	.logo {
		width: 80px;
		max-width: 100%;
		min-width: auto;
		margin: 0 auto;
	}

	a {
		color: #E30059;
	}

	a:hover {
		color: #fff;
	}

	p {
		margin-top: 4px;
		text-align: center;
		vertical-align: center;
	}

	.location,
	.appointments {
		text-align: center;
		color: #000;
		font-size: 14px;

		i {
			vertical-align: middle;
		}
	}

	table {
		text-align: center;
		margin: 0 auto;
	}
}

.btn {
	&:active {
		background-color: #FFF;
	}

	&:hover {
		background-color: #E30059 !important;
		color: #FFF !important;
	}

	&:focus {
		background-color: #FFF;
		color: #E30059;
	}
}
</style>
