<template>
  <div>
    <AppHeader />
    <notifications position="center right" classes="kkk-notification" />
    <router-view />
    <AppFooter />
    <cookie-banner />
    <BackToTop />
  </div>
</template>

<script>
import AppHeader from '@/components/header/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import CookieBanner from '@/components/CookieBanner.vue';
import BackToTop from '@/components/controls/BackToTop.vue';
export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
    CookieBanner,
    BackToTop,
  },
  beforeCreate() {
    /*load all the data needed to run this app*/
    this.$store.dispatch('loadTags');
    this.$store.dispatch('loadCategories');
    this.$store.dispatch('loadInstitutions');
  },
  methods: {

  }
}
</script>
<style lang="scss">
@import '@/scss/_variables.scss';

.vue-notification-group {
  margin-top: 80px;

  .vue-notification-wrapper {

    .kkk-notification {
      margin: 0px 10px 10px 10px;
      padding: 10px 30px;
      color: $primary;
      border-bottom-left-radius: 20px;
      box-shadow: -5px 5px 10px 0px rgba($color: $primary, $alpha: 0.2);
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      .notification-title {
        color: $secondary;
      }

      .notification-content {
        color: $primary;
      }

      &.error {
        border: 2px solid $secondary;
      }

    }
  }
}
</style>
