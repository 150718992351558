<template>
    <div class="dependency-filter-structure-wrapper">

        <!--Display the parentField-->
        <WBSelectField class="parentField mb15" :name="basicFilter.label" :field-type-id="basicFilter.field_type_id"  :values="basicFilter.options" :selectedFilters="selectedFilters" :isMultiple="true" @on-select="filterChanged" />

        <!-- We get into this recursive part here if the parentField is a depender. Then we have to check if the dependencies to display the child fields are met -->
        <DependencyFilterStructure v-if="parentField.type == 'depender' && dependencyFulfilled(item)" v-for="(item, index) in parentField.options" :key="item.field_type_id"  class="childField" :parentField="item" :selectedFilters="selectedFilters" @on-select="filterChanged" />

    </div>
</template>

<script>
    import { createUniqueId, on, off, elementContains } from '@/utils/wb-helper';
    import { shortenString, getBasicFilters } from '@/utils/betterHelpers';
    export default {
        name: 'DependencyFilterStructure',
        components: {
            Checkbox: () => import('./Checkbox.vue'),
            WBSelectField: () => import('./WBSelectField.vue'),
        },
        props: {
            parentField: {
                type: Object,
                required: true,
            },
            selectedFilters: {
                type: Map,
                default: null,
            }
        },
        data: () => ({
            id: createUniqueId(),
        }),
        computed: {
            basicFilter(){
                var filters = [];
                filters.push(this.parentField);
                filters = getBasicFilters(filters);
                return filters.pop();
            }
        },
        methods: {
            shortenString,
            dependencyFulfilled(item){
                /*checking selectedFilters*/
                if(this.selectedFilters!==null){
                    var res = this.selectedFilters.get(this.parentField.field_type_id);
                    if(res!==null && res !== undefined){
                        /*we've got a result - now we need to check if the current item should be shown because it was selected in the parent*/
                        if(!Array.isArray(res)){
                            /*make sure that whatever is in res is an array*/
                            var gna = [];
                            gna.push(res);
                            res = gna;
                        }
                        for(var i = 0; i<res.length; i++){
                            if(res[i] == item.label){
                                return true;
                            }
                        }
                    }
                }
            },
            filterChanged(fieldTypeId,values){
                /*einfach weitergeben*/
                this.$emit('on-select', fieldTypeId, values);

                /*jetzt müsste hier nur geschaut werden das alle unter-unterfilter usw. auch gelöscht werden wenn die Kind-Elemente gelöscht werden*/
                if(this.parentField.type == 'depender'){
                    for(var i = 0; i<this.parentField.options.length; i++){
                        var option = this.parentField.options[i];
                        /*schauen ob die Abhängigkeit erfüllt wurde*/
                        if(!this.dependencyFulfilled(this.parentField.options[i])){
                            /*checken ob die selectedFilters einen Wert für die Option haben*/
                            var res = this.selectedFilters.get(option.field_type_id);
                            if(res!==null && res !== undefined){
                                /*wenn das Ding gesetzt ist müssen wir die dort hinterlegten Werte leeren*/
                                this.$emit('on-select', option.field_type_id, null);
                            }
                        }
                    }
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';
    @import '@/scss/_mixins.scss';

</style>