<template>
    <div class="row event-section">
        <div class="col-12 recommendations" v-if="events != null">
            <div class="section-header">
                <h3 class="section-title">Das geht in Kiel - Events</h3>
                <router-link to="/kultur-kalender" class="view-all-link">Alle Events anzeigen</router-link>
            </div>
            <div class="row scroll-container">
                <div class="card-wrapper" v-for="event in events.slice(0, 4)" :key="event.id">
                    <CardEvent :content="event" :marginRight="'0px'" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CardEvent from '@/components/cards/CardEvent.vue';


import { getContentsOfType } from '@/api/content';
import { showLoader, hideLoader } from '@/utils/helpers';

export default {
    name: 'EventSection',
    components: {
        CardEvent,
    },
    data() {
        return {
            loader: null,
            eventDataSet: {
                total: 0,
                data: null,
                meta: null,
                query: {
                    page: 1,
                    limit: 50,
                    keyword: '',
                    type: 'view',
                    selectedAppId: this.$appId,
                    view_status: 1,
                    selectedContentTypeId: 2,
                    sort: {
                        prop: '',
                        order: 'asc'
                    },
                },
            },
        }
    },
    computed: {
        events() {
            if (this.eventDataSet.data != null && this.eventDataSet.data.length > 0) {
                /*hard-coded Langcode*/
                return this.eventDataSet.data;
            }
            return null;
        },
    },
    created() {
        this.getEventList();
    },
    methods: {
        showLoader,
        hideLoader,
        async getEventList() {
            this.loader = this.showLoader(this.loader);
            const { limit, page } = this.eventDataSet.query;
            const { data, meta } = await getContentsOfType(this.$offerId, this.eventDataSet.query);
            this.eventDataSet.data = data;
            this.eventDataSet.data.forEach((element, index) => {
                element['index'] = (page - 1) * limit + index + 1;
            });
            this.eventDataSet.meta = meta;
            this.eventDataSet.total = meta.total;
            this.loader = this.hideLoader(this.loader);
        },
    }
}
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.section {
    background-color: transparent;
}

.main-section {
    padding: 0px 80px;
}

.institution-card {
    max-width: 31%;
}

.recommendations {
    @media (max-width: 1090px) and (min-width: 992px) {
        width: 100%;
    }
}

.container {

    @media (max-width: 992px) {
        max-width: 100%;
        padding: 0px 40px;
    }


    @media (max-width: 500px) {
        padding: 0px 20px;
    }

}



.scroll-container {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 0px;
    width: auto;

    .card-wrapper {
        flex: 1 1 calc(25% - 16px);
        max-width: calc(25% - 16px);
        min-width: 0;
    }

    @media (max-width: 1250px) {
        flex-wrap: nowrap; // Prevent wrapping on smaller screens
        overflow-x: auto; // Enable horizontal scrolling

        .card-wrapper {
            flex: 0 0 calc(50% - 16px); // Adjust to 50% width minus gap
            max-width: calc(50% - 16px);
        }
    }

    @media (max-width: 731px) {
        .card-wrapper {
            flex: 0 0 calc(100% - 16px); // Full width minus gap on very small screens
            max-width: calc(100% - 16px);
        }
    }
}

.card-wrapper {
    display: flex;
    width: 100%;
    max-width: none;

    :deep(.card-event) {
        width: 100%;
        height: 100%;
    }
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .section-title {
        margin-bottom: 0; // überschreibt mb20
    }

    .view-all-link {
        color: $secondary; // oder Ihre gewünschte Farbe
        text-decoration: underline;
        font-weight: 600;
        font-size: 16px;

        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }
}
</style>
<style lang="scss">
.event-section {
    .card-event {
        width: 100%;
        height: 100%;
    }
}
</style>