import Vue from 'vue'
import VueRouter from 'vue-router'

/*get access to the store*/
import store from '@/store';

Vue.use(VueRouter);

import Home from '../views/home';
import Suche from '../views/search/index.vue';
import Karte from '../views/mapview/show.vue';
import KulTouren from '../views/touren/index.vue';
import Kulturspur from '../views/touren/show.vue';
import Anbieterprofil from '../views/institution/show.vue';
import Veranstaltung from '../views/event/show.vue';
import Verzeichnis from '../views/verzeichnis/index.vue';
import KulturKalender from '../views/calendar/calendar.vue';
import Profil from '../views/profil/index.vue';
import SystemView from '../views/system/show.vue';
import Login from '../views/auth/login.vue';
import Register from '../views/auth/register.vue';
import ChangePassword from '../views/auth/changePassword.vue';
import ApplyForNewPassword from '../views/auth/applyForNewPassword.vue';
import PasswordForgotten from '../views/auth/passwordForgotten.vue';
import MyData from '../views/auth/mydata.vue';
import Collection from '../views/collections/index.vue';
import Attraktion from '@/views/attraction/show.vue';
import Story from '@/views/story/show.vue';
import HomeAlt from '../views/home/indexalt.vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home-alt',
    name: 'HomeAlt',
    component: HomeAlt
  },
  {
    path: '/suche',
    name: 'Suche',
    component: Suche
  },
  {
    path: '/karte',
    name: 'Karte',
    component: Karte
  },
  {
    path: '/kul-touren',
    name: 'KulTouren',
    component: KulTouren
  },
  {
    path: '/kultouren/:id',
    name: 'Kulturspur',
    component: Kulturspur
  },
  {
    path: '/attraction/:id',
    name: 'Attraktion',
    component: Attraktion
  },
  {
    path: '/story/:id',
    name: 'Story',
    component: Story
  },
  {
    path: '/anbieterprofil/:id',
    name: 'Anbieterprofil',
    component: Anbieterprofil
  },
  {
    path: '/veranstaltung/:id',
    name: 'Veranstaltung',
    component: Veranstaltung
  },
  {
    path: '/verzeichnis',
    name: 'Verzeichnis',
    component: Verzeichnis
  },
  {
    path: '/kultur-kalender',
    name: 'KulturKalender',
    component: KulturKalender
  },
  {
    path: '/profil',
    name: 'Profil',
    component: Profil,
    meta: {
      auth: true
    }
  },
  {
    path: '/collection/:id',
    name: 'Collection',
    component: Collection,
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: SystemView
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: SystemView
  },
  {
    path: '/barrierefreiheit',
    name: 'barrierefreiheit',
    component: SystemView
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      auth: false
    }
  },
  {
    path: '/new-password',
    name: 'newPassword',
    component: ChangePassword,
    meta: {
      auth: true
    }
  },
  {
    path: '/apply-for-new-password',
    name: 'applyForNewPassword',
    component: ApplyForNewPassword,
    meta: {
      auth: false
    }
  },
  {
    path: '/password-reset/:token',
    name: 'passwordForgotten',
    component: PasswordForgotten,
    meta: {
      auth: false
    }
  },
  {
    path: '/my-data',
    name: 'myData',
    component: MyData,
    meta: {
      auth: true
    }
  },
  {
    path: '/verify/:token',
    name: 'Verification',
    component: Login,
  }
]

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  setTimeout(() => { window.scrollTo(0, 0); }, 100);
  
  var loginTime = store.getters.getLoginTime;
  if(loginTime!=null){
    var currentTime = Date.now();
    var timeDifferenceInSecond = Math.ceil(Math.abs(currentTime-loginTime)/1000);
    var timeDifferenceInMinutes=timeDifferenceInSecond/60;
    if(timeDifferenceInMinutes>60){
      /*logout*/
      store.dispatch('logout');      
    }
    //console.log(timeDifferenceInMinutes);
  }
  
  
  const token = store.getters['getToken'];
  if (to.meta.auth == true && token === null) {
    /*go to login - only users that are logged in are allowed*/
    next('/login');
    return;
  }
  else if (to.meta.auth == false && token != null){
    /*go home - only not logged in users are allowed*/
    next('/');
    return;    
  }
  next();
});

export default router
