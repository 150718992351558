<template>
  <div class="collections" v-if="this.filteredKultouren !== null && this.filteredKultouren.length > 0">
    <span class="title">Weitere Kulturspuren für dich:</span>
    <div class="horizontal-scroll scroll-container">
      <div class="horizontal-scroll-card d-flex align-items-stretch">
        <CardKultour v-for="(kultour, index) in filteredKultouren" class="d-flex align-items-stretch card-wrapper"
          :id="'horizontal-collection-' + kultour.id" :collection="kultour" :isSmall="true" marginBottom="20px"
          marginRight='20px' />
      </div>
    </div>
  </div>
</template>

<script>
import { getAppCollections } from '@/api/collection';
import { search } from '@/api/content';

export default {
  name: 'MoreTours',
  components: {
    CardKultour: () => import('@/components/cards/CardKultour.vue'),
  },
  props: {
    currentId: {
      required: true
    }
  },
  data() {
    return {
      kultourDataSet: {
        total: 0,
        data: null,
        meta: null,
        query: {
          page: 1,
          limit: 4,
          selectRandomly: true,
          type: 'view',
          view_status: 1,
          resourceType: 'view',
          selectedContentTypeId: this.$kultourId,
          keyword: '',
          sort: {
            prop: '',
            order: 'asc'
          },
        },
      },
    };
  },
  computed: {
    filteredKultouren() {
      if (this.kultourDataSet.data !== null) {
        var kultouren = this.kultourDataSet.data;

        var currentKultourPos = kultouren.map(function (x) { return x.id; }).indexOf(this.currentId);
        if (currentKultourPos !== -1) {
          kultouren.splice(currentKultourPos, 1);
        }
        else if (kultouren.length > 3) {
          kultouren.pop()
        }
        return kultouren;
      }
      return null
    }
  },
  created() {
    this.getRandomKultouren();
  },
  methods: {
    async getRandomKultouren() {
      const { limit, page } = this.kultourDataSet.query;
      const { data, meta } = await search(this.kultourDataSet.query);
      this.kultourDataSet.data = data;
      data.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });

      this.kultourDataSet.meta = meta;
      this.kultourDataSet.total = meta.total;
    },
  },

};
</script>
<style lang="scss" scoped>
.collections {
  margin-top: 40px;

  .title {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    font-family: 'elliot-bold', sans-serif;
  }

  .horizontal-scroll {
    display: flex;
    flex-direction: row;
    margin: 0px -10px;
    padding: 0px 10px;
    margin-top: 15px;
  }

  .card:last-child {
    margin-right: 0 !important;
  }


  .collection-card {
    max-width: 320px;
    margin-right: 15px !important;

    @media(max-width:991px) {
      width: auto !important;
    }
  }
}
</style>
