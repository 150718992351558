<template>
    <div class="profile footer-margin">
        <Breadcrumb />

        <div class="container">
            <div class="profile-header">
                <div class="profile-image">
                    <splide :options="sliderOptions" ref="teaserCarousel" class="full-width"
                        v-if="sliderImages != null && sliderImages.length > 1">
                        <splide-slide class="full-width" v-for="(slide, index) in sliderImages" :key="index">
                            <div class="full-width profile-image-placeholder" :class="slide.class"
                                :style="getBackgroundImage(slide.image)"></div>
                            <span class="copyright" v-if="slide.copyright != null">&copy; {{ slide.copyright }}</span>
                        </splide-slide>
                    </splide>
                    <div v-else-if="sliderImages != null && sliderImages.length == 1">
                        <div class="full-width profile-image-placeholder" :class="sliderImages[0].class"
                            :style="getBackgroundImage(sliderImages[0].image)"></div>
                        <span class="copyright" v-if="sliderImages[0].copyright != null">&copy;
                            {{ sliderImages[0].copyright }}</span>

                    </div>
                    <button title="Merken" class="btn btn-link btn-favorite" :class="isInCollectionClass"
                        @click="showAddToCollectionDialog = true">
                        <i class="material-icons" aria-hidden="true">anchor</i>
                    </button>
                </div>
                <div class="profile-heading d-flex justify-content-between">
                    <div class="profile-heading__title">
                        <span>{{ categories }}</span>
                        <h4>{{ title }}</h4>
                    </div>

                    <share-button :is-device-mobile="isItMobile" />

                </div>
            </div>

            <router-link :to="{ name: 'Anbieterprofil', params: { id: institutionId } }" class="profile-info__user">
                <div class="inline-icon-text">
                    <i class="material-icons" aria-hidden="true">person</i>
                    <h5>{{ institution }}</h5>
                </div>
            </router-link>

            <ContactInformation class="contact-info" :url="url" :mail="mail" :phone="phone" />

            <div class="row time-and-place">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 time-and-place-col">

                    <Appointments :appointments="appointments" />

                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 time-and-place-col">
                    <div :class="['dropdown']" class="show-me-the-money">
                        <div class="dropdown-head hide-dropdown" v-if="price != null && price != 'kostenlos'">
                            <h5><i class="material-icons icon euro-icon" aria-hidden="true">euro_symbol</i>{{ price }}
                            </h5>
                        </div>
                        <div class="dropdown-head hide-dropdown" v-else>
                            <h5><i class="material-icons icon euro-icon" aria-hidden="true">euro_symbol</i>Kostenlos
                            </h5>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 col-12 time-and-place-col">
                    <div :class="['dropdown', isActiveLocation ? 'is-active' : '']" class="address"
                        v-if="addressObj != null">
                        <button class="dropdown-head no-btn" @click="isActiveLocation = !isActiveLocation">
                            <div class="inline-icon-text">
                                <i class="material-icons icon" aria-hidden="true">location_on</i>
                                <h5 style="text-align: left">{{ adresse }}</h5>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <div :class="['dropdown', isActiveLocation ? 'is-active' : '']" v-if="addressObj">
                <div class="dropdown-list map-dropdown" style="height: 500px;">
                    <the-map class="full-width" :address-obj="addressObj"></the-map>
                </div>
            </div>


            <div class="profile-text mt20">
                <CardSimple :description="description" :tags="tags" />
            </div>

            <similar-events style="width:100%;" :data-set="similarEventsDataSet" />


            <CardConnection class="mt30" :address="adresse" />

        </div>

        <AddContentToCollection :content="content" v-if="showAddToCollectionDialog"
            @closeModal="showAddToCollectionDialog = false"></AddContentToCollection>

    </div>
</template>

<script>
import Resource from '@/api/resource';
import TheMap from '@/components/map/single.vue';
import Breadcrumb from '@/components/controls/Breadcrumb.vue';
import CardSimple from '@/components/cards/CardSimple.vue';
import CardConnection from '@/components/cards/CardConnection.vue';
import Appointments from '@/components/details/appointments.vue';
import ContactInformation from '@/components/details/contactInformation.vue';
import { getFieldValues, getPlaceholderImage, showLoader, hideLoader, json_decode } from '@/utils/helpers';
import SimilarEvents from './components/events.vue';
import { isMobile } from 'mobile-device-detect';
import ShareButton from '@/components/controls/ShareButton.vue';
import AddContentToCollection from '@/components/modal/addContentToCollection/dialog.vue';

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { getContentsOfType } from '@/api/content';
const contentResource = new Resource('contents');

export default {
    name: 'Event',
    components: {
        Breadcrumb,
        CardSimple,
        CardConnection,
        Splide,
        ShareButton,
        SplideSlide,
        Appointments,
        ContactInformation,
        SimilarEvents,
        TheMap,
        AddContentToCollection
    },
    data() {
        return {
            loader: null,
            content: null,
            sliderImages: null,
            sliderOptions: {
                type: 'loop',
            },
            showAddToCollectionDialog: false,
            isItMobile: isMobile ? true : false,
            similarEventsDataSet: {
                total: 0,
                data: null,
                meta: null,
                query: {
                    page: 1,
                    limit: 3,
                    view_status: 1,
                    keyword: this.category,
                    selectedAppId: this.$appId,
                    selectedContentTypeId: this.$offerId,
                    selectRandomly: true,
                    sort: {
                        prop: '',
                        order: 'asc'
                    },
                },
            },
            isActiveLocation: false,
        }
    },
    watch: {
        id(newId, oldId) {
            if (newId != oldId) {
                this.getContent();
                this.isActiveLocation = true;
                this.scrollToTop();
            }
        }
    },
    created() {
        this.getContent();
        this.isActiveLocation = true;
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        isLoggedIn() {
            if (this.$store.getters.getToken) {
                return true;
            }
            return false;
        },
        collections() {
            return this.$store.getters.getCollections;
        },
        isInCollectionClass() {
            if (this.isLoggedIn && this.isContentInCollection()) {
                return 'active';
            }
            return '';
        },
        institution() {
            if (this.content) {
                return this.content.institution_title;
            }
            return null;
        },
        institutionId() {
            if (this.content) {
                return this.content.institution_id;
            }
            return null;
        },
        tags() {
            var tags = getFieldValues(this.content, 'kultur_-_tags');
            if (tags != null) {
                if (Array.isArray(tags)) {
                    return tags;
                }
                else {
                    return [tags];
                }
            }
            return null;
        },
        description() {
            var desc = getFieldValues(this.content, 'description');
            var more = getFieldValues(this.content, 'weitere_infos');
            var registration = getFieldValues(this.content, 'anmeldung');

            if (more != null) {
                more = "<br><strong>Weitere Informationen:</strong><br>" + more;
                desc = desc + more;
            }

            if (registration != null) {
                registration = "<br><strong>Informationen zur Anmeldung:</strong><br>" + registration;
                desc = desc + registration;
            }

            return desc != null ? desc : '';
        },
        title() {
            if (this.content != null) {
                return this.content.title;
            }
            return "";
        },
        categories() {
            var cats = getFieldValues(this.content, 'kultur_kategorien');
            if (Array.isArray(cats)) {
                return cats.join(', ');
            }
            else if (cats != null) {
                return cats;
            }
            return "";
        },
        category() {
            if (this.categories != null && this.categories.length > 0) {
                return this.categories[0];
            }
            return null
        },
        addressObj() {
            return getFieldValues(this.content, 'adresse');
        },
        adresse() {
            if (this.addressObj != null) {
                return this.addressObj.street + ', ' + this.addressObj.zipcode + ' ' + this.addressObj.city;
            }
            return '';
        },
        appointments() {
            return getFieldValues(this.content, 'appointments');
        },
        teaserImages() {
            var pictures = getFieldValues(this.content, 'teaser-bild');
            if (pictures != null) {
                if (Array.isArray(pictures)) {
                    return pictures;
                }
                else {
                    return [pictures];
                }
            }
            return null;
        },
        teaserImage() {
            var images = this.teaserImages;
            if (images != null && Array.isArray(images) && images.length > 0) {
                var image = images[Math.floor(Math.random() * images.length)];
                return this.$backendUrl + image;
            }
            else if (images != null) {
                return this.$backendUrl + images;
            }

            /*we'll need an alternative if there is no image*/
            return this.getPlaceholderImage(this.category);

        },
        contactInfo() {
            return getFieldValues(this.content, 'kontaktinformationen');
        },
        url() {
            return getFieldValues(this.content, 'url');
        },
        mail() {
            return getFieldValues(this.contactInfo, 'e-mail_adresse');
        },
        phone() {
            return getFieldValues(this.contactInfo, 'telefonnummer');
        },
        price() {
            return getFieldValues(this.content, 'kosten');
        }
    },
    methods: {
        getFieldValues,
        getPlaceholderImage,
        showLoader,
        hideLoader,
        json_decode,
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getBackgroundImage(image) {
            return 'background-image: url("' + image + '")';
        },
        async getSimilarContents() {
            const { limit, page } = this.similarEventsDataSet.query;
            const { data, meta } = await getContentsOfType(this.$offerId, this.similarEventsDataSet.query);
            this.similarEventsDataSet.data = data;
            this.similarEventsDataSet.data.forEach((element, index) => {
                element['index'] = (page - 1) * limit + index + 1;
            });
            this.similarEventsDataSet.meta = meta;
            this.similarEventsDataSet.total = meta.total;
        },
        getSlides() {
            var images = [];
            if (this.teaserImages != null) {
                for (var i = 0; i < this.teaserImages.length; i++) {
                    var image = json_decode(this.teaserImages[i]);
                    if (typeof image === "object") {
                        if (image.path.includes("http")) {
                            images.push({ image: image.path, class: 'teaser-img', copyright: image.copyright, description: image.description });
                        }
                        else {
                            images.push({ image: this.$backendUrl + image.path, class: 'teaser-img', copyright: image.copyright, description: image.description });
                        }
                    }
                    else {
                        if (image.includes("http")) {
                            images.push({ image: image, class: 'teaser-img', copyright: null, description: null });
                        }
                        else {
                            images.push({ image: this.$backendUrl + image, class: 'teaser-img', copyright: null, description: null });
                        }
                    }
                }
            }
            else {
                images.push({ image: this.teaserImage, class: 'teaser-img', copyright: null, description: null });
            }
            return images;
        },
        getContent() {
            this.loader = this.showLoader(this.loader);
            contentResource.get(this.id)
                .then(response => {
                    this.content = response.data;
                    this.sliderImages = this.getSlides();
                    this.getSimilarContents(); 235653
                })
                .finally(() => {
                    this.loader = this.hideLoader(this.loader);
                });
        },
        isContentInCollection() {
            if (this.collections != null) {
                for (var prop in this.collections) {
                    var collectionGroup = this.collections[prop];
                    for (var i = 0; i < collectionGroup.length; i++) {
                        var currentCollection = collectionGroup[i];
                        if (currentCollection != null && currentCollection.contents.length > 0) {
                            for (var j = 0; j < currentCollection.contents.length; j++) {
                                if (currentCollection.contents[j] != null && this.content != null && currentCollection.contents[j].id == this.content.id) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
            return false;
        },
        toggleMap() {
            this.isActiveLocation = !this.isActiveLocation;
            /*hide map if activeTime is shown*/
            if (this.isActiveLocation) {
                this.isActiveTime = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.profile {
    position: relative;
    background: #fff;
    padding-top: 110px;
    padding-bottom: 40px;

    .container {
        max-width: 705px;
    }

    .euro-icon {
        color: rgba(31, 32, 65, 0.75);
    }

    .profile-header {
        @media (max-width: 767px) {
            padding: 0px 20px;
        }
    }

    .btn.btn-round.special {
        color: #00183E;
        border: 2px solid #00183E;
    }

    .btn.btn-round.special:hover,
    .btn.btn-round.special:active,
    .btn.btn-round.special:focus {
        color: #fff;
        background-color: #00183E;
    }

    .address {

        @media (max-width: 767px) {
            margin-top: 0px;
        }
    }

    .contact-info {
        margin: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 20px !important;

        @media (max-width: 991px) {
            margin-bottom: 0px !important;
        }
    }

    .time-and-place {

        @media (max-width: 991px) {
            padding-top: 10px;
        }

        @media (max-width: 767px) {
            margin-top: 0px;
        }

        .time-and-place-col {

            @media (max-width: 767px) {
                width: 100%;
                text-align: center !important;
                margin-top: 10px;
            }

            .show-me-the-money {
                text-align: center;

                @media (max-width: 991px) {
                    text-align: right;
                }

                @media (max-width: 767px) {
                    text-align: center;
                }
            }

            .dropdown-head h5 {
                font-size: 16px !important;
            }

            &:nth-child(2) {
                text-align: right;

                @media (max-width:576px) {
                    text-align: left;
                }
            }

            i {
                vertical-align: bottom;
                margin-right: 10px;
            }

        }

    }

    &-image {
        position: relative;
        z-index: 1;

        &-placeholder {
            height: 371px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            &.logo {
                background-size: contain !important;
            }

            @media (max-width: 560px) {
                height: 260px;
            }

            @media (max-width: 480px) {
                height: 220px;
            }
        }

        @media (max-width: 767px) {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    &-heading {
        position: relative;
        z-index: 2;
        top: 0;
        padding: 10px 20px;
        box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
        border-radius: 5px;
        border-bottom-left-radius: 30px;

        @media (max-width: 767px) {
            margin-left: -15px;
            margin-right: -15px;
        }

        &__title {
            width: calc(100vw - 70px);
            margin-bottom: 10px;

            h4 {
                margin-top: 5px;
            }
        }
    }

    &-info__user {
        margin-top: 30px;
        color: $secondary;
        display: flex;
        vertical-align: middle;
        align-items: center;

        @media (max-width: 991px) {
            padding-bottom: 10px;
        }

        @media (max-width: 575px) {
            width: 100% !important;
            text-align: center !important;
        }

        .inline-icon-text {
            @media (max-width: 767px) {
                text-align: center;
                width: 100%;
                display: block;
            }
        }

        h5 {
            color: $secondary;
            margin-bottom: 0;
        }
    }

    @media (max-width: 767px) {
        padding-top: 70px;
    }

    @media (max-width: 350px) {
        padding-top: 60px;
    }
}
</style>
