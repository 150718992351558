<template>
    <div class="appointments" :class="['dropdown', isActiveTime ? 'is-active' : '']" :style="additionalStyles">

        <button class="dropdown-head no-btn" v-if="isCurrentlyActive" @click="toggleUhrzeit">
            <i class="material-icons icon highlight" aria-hidden="true">access_time</i>
            <h5 class="highlight text-align">Jetzt geöffnet!</h5>
        </button>
        <button class="dropdown-head no-btn" v-else-if="nextAppointment != null" @click="toggleUhrzeit">
            <h5>
                <span v-if="nextAppointment.date == null && nextAppointment.wday != null"><i
                        class="material-icons icon">access_time</i>
                    {{ getWeekday(nextAppointment.wday).substring(0, 2) }}, {{
                        formatTime(nextAppointment.time.time_from)
                    }} Uhr
                </span>
                <span v-if="nextAppointment.date != null"><i class="material-icons icon"
                        aria-hidden="true">access_time</i>
                    {{ formatDate(nextAppointment.date) }}, {{ formatTime(nextAppointment.time.time_from) }} Uhr
                </span>
            </h5>
        </button>

        <div v-else class="dropdown-head no-appointments">
            <div class="inline-icon-text">
                <i class="material-icons icon" aria-hidden="true">block</i>
                <h5>Keine aktuellen Termine</h5>
            </div>
        </div>

        <display-appointments v-if="isActiveTime" class="display-appointments" :appointments="appointments"
            :active="isCurrentlyActive" />

    </div>
</template>

<script>

import DisplayAppointments from './appointmentsDropdownContents.vue';
import { getNextAppointment, isAppointmentNow, formatDate, formatTime, getWeekday, isOneOfTheAppointmentsNow, getMonth } from '@/utils/helpers';

export default {
    name: "appointmentsDropdown",
    components: {
        DisplayAppointments,
    },
    props: {
        appointments: {
            type: Object,
            default: null,
        },
        additionalStyles: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            isActiveTime: false
        }
    },
    computed: {
        nextAppointment() {
            return getNextAppointment(this.appointments);
        },
        nextAppointmentWday() {
            if (this.nextAppointment != null && this.nextAppointment.wday != null) {
                var day = getWeekday(this.nextAppointment.wday);
                return day.substring(0, 2);
            }
            return null;
        },
        nextAppointmentDate() {
            if (this.nextAppointment != null && this.nextAppointment.date != null) {
                var date = new Date(this.nextAppointment.date);
                return date.getDate();
            }
            return null;
        },
        nextAppointmentStartTime() {
            if (this.nextAppointment != null && this.nextAppointment.time != null && this.nextAppointment.time.time_from != null) {
                var time = this.nextAppointment.time.time_from;
                return formatTime(time);
            }
            return null;
        },
        nextAppointmentMonthAndYear() {
            if (this.nextAppointment != null && this.nextAppointment.date != null) {
                var date = new Date(this.nextAppointment.date);
                var month = getMonth(date.getMonth() + 1);
                var year = date.getFullYear();
                return month.substring(0, 3) + '. ' + year;
            }
            return null;
        },
        isCurrentlyActive() {
            if (this.appointments != null && Array.isArray(this.appointments.days)) {
                const today = new Date();
                today.setHours(0, 0, 0, 0); // Reset time to start of the day
                return this.appointments.days.some(day => {
                    const appointmentDate = new Date(day.date);
                    appointmentDate.setHours(0, 0, 0, 0); // Reset time to start of the day
                    return appointmentDate.getTime() === today.getTime() && day.times.some(time => isAppointmentNow(time));
                });
            }
            return false;
        },
    },
    methods: {
        formatTime,
        isAppointmentNow,
        formatDate,
        getWeekday,
        toggleUhrzeit() {
            this.isActiveTime = !this.isActiveTime;
            /*hide map if activeTime is shown*/
            if (this.isActiveTime) {
                this.isActiveLocation = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.appointments {
    white-space: nowrap;
}

.appointments h5 i {
    vertical-align: bottom;
    margin-bottom: -3px;
}

.appointments h5 {
    min-width: 150px;
    font-size: 16px;
}

.dropdown-head {
    text-align: left;


    &.no-appointments::after {
        content: '';
        display: none;
    }
}

.text-align {
    text-align: left;
    padding-left: 5px;
}
</style>
