<template>
    <div class="display-appointments" v-if="appointments != null">
        <table class="appointments-table">
            <tr v-for="(appo, index) in uniqueAppointments" :key="index" class="dropdown-list"
                :class="isCurrentlyActive(appo)">
                <td class="date-cell">
                    <span v-if="appo.date == null && appo.wday != null">
                        {{ getWeekday(appo.wday) }}
                    </span>
                    <span v-if="appo.date != null">
                        {{ formatDate(appo.date) }}
                    </span>
                </td>
                <td>
                    <div v-for="(time, index) in appo.times" :key="index">{{ formatTime(time.time_from) }} -
                        {{ formatTime(time.time_to) }}</div>
                </td>
            </tr>
        </table>

        <div class="exceptions" v-if="appointments.exceptions != null && appointments.exceptions.length > 0">
            <div>Ausnahmen:</div>
            <div v-for="(exc, index) in appointments.exceptions" :key="index"> {{ formatDate(exc.start_date) }} -
                {{ formatDate(exc.end_date) }} </div>
        </div>

    </div>
</template>

<script>

import { getWeekday, formatTime, formatDate, getCurrentDate, getCurrentTime, getCurrentWeekDay, isAppointmentNow } from '@/utils/helpers';

export default {
    name: 'displayAppointments',
    props: {
        appointments: {
            type: Object,
            default: null,
        },
        active: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        currentDate() {
            return getCurrentDate();
        },
        currentTime() {
            return getCurrentTime();
        },
        currentWeekDay() {
            return getCurrentWeekDay();
        },
        uniqueAppointments() {
            if (!this.appointments || !this.appointments.days) return [];

            const uniqueDays = [];
            const seen = new Set();

            this.appointments.days.forEach(appo => {
                if (appo.date === null) {
                    const key = `${appo.wday}-${appo.times.map(time => `${time.time_from}-${time.time_to}`).join(',')}`;
                    if (!seen.has(key)) {
                        seen.add(key);
                        uniqueDays.push(appo);
                    }
                } else {
                    uniqueDays.push(appo);
                }
            });

            return uniqueDays;
        }
    },
    methods: {
        getWeekday,
        formatTime,
        formatDate,
        getCurrentDate,
        getCurrentTime,
        isAppointmentNow,
        getCurrentWeekDay,
        isCurrentlyActive(appointment) {
            if (appointment != null && this.active) {
                const currentDate = new Date(this.currentDate);
                const appointmentDate = new Date(appointment.date);

                if (appointmentDate.toDateString() === currentDate.toDateString() && isAppointmentNow(appointment)) {
                    return "active";
                }
            }
            return 'inactive';
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.display-appointments {

    margin-left: 40px;

    @media (max-width: 500px) {
        margin-left: 0px;
    }

    .appointments-table {


        @media (max-width: 767px) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.display-appointments .date-cell {
    width: 80px;
    vertical-align: top;
}

.display-appointments .exceptions {
    margin-top: 10px;
}

.display-appointments .active {
    font-family: 'elliot-regular', sans-serif;
    font-weight: 700;
}

.display-appointments table {
    @media (max-width: 500px) {
        margin: 0 auto;
    }
}
</style>