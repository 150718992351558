<template>
    <div class="footer-margin kultour-home-alt">
        <div class="bg-image">

            <div class="banner">
                <div class="banner-content">
                    <div class="container banner-container">
                        <div class="row">
                            <div class="text-container col-12 col-lg-7 col-xl-5">
                                <h1 class="banner-text"><span class="banner-text-bold">Kultur</span> für Kiel</h1>
                                <div class="banner-text-description" v-html="body">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-section">
                <div class="section">
                    <div class="row card-container">
                        <div v-for="category in categories" class="col-6 col-lg-3 cat">
                            <CardCategory :isSmall=true class="category-card" :key="category.id" :content="category" />
                        </div>
                    </div>
                </div>

                <div class="section">
                    <EventSection />
                </div>

                <div class="section">
                    <KulTourSection :touren="touren" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Resource from '@/api/resource';
const contentResource = new Resource('contents');

import EventSection from './components/EventSection.vue';
import KulTourSection from './components/KulTourSection.vue';
import CardInstitution from '@/components/cards/CardInstitution.vue';
import CardCategory from './components/CardCategory.vue';
import CardText from './components/CardText.vue';
import { getFieldValues, isset, showLoader, hideLoader } from '@/utils/helpers';
import { getFirstFieldValue } from '@/utils/betterHelpers';

export default {
    name: 'Home',
    components: {
        CardInstitution,
        CardCategory,
        CardText,
        EventSection,
        KulTourSection,
    },
    data() {
        return {
            loader: null,
            categories: [{ title: 'Events' }, { title: 'KulTouren' }, { title: 'Orte' }, { title: 'Login' }],
            content: null,
            touren: [],
        }
    },
    computed: {
        body() {
            if (this.content !== null) {
                return getFirstFieldValue(this.content, 'body');
            }
            return null;
        },
        tourenIds() {
            if (this.content !== null) {
                return getFieldValues(this.content, 'touren');
            }
            return null;
        },
    },
    methods: {
        showLoader,
        hideLoader,
        getFirstFieldValue,
        getFieldValues,
        isset,
        getContent(id) {
            this.loader = this.showLoader(this.loader);
            contentResource.get(id)
                .then(response => {
                    this.content = response.data;
                    this.getTouren();
                })
                .finally(() => {
                    this.loader = this.hideLoader(this.loader);
                });
        },
        getTouren() {
            var touren = [];
            if (this.content !== null && isset(this.tourenIds)) {
                const promises = this.tourenIds.map(tourenId =>
                    contentResource.get(tourenId)
                        .then(response => {
                            if (response && response.data) {
                                const tour = response.data;
                                return tour;
                            }
                            return null;
                        })
                        .catch(error => {
                            console.warn(`Failed to load touren ${tourenId}:`, error);
                            return null;
                        })
                );

                Promise.all(promises)
                    .then(loadedTouren => {
                        this.touren = loadedTouren.filter(touren => touren !== null);
                    });
            } else {
                this.touren = [];
            }
        },
    },
    mounted() {
        this.getContent(292883);
    }
}
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.kultour-home-alt {
    height: 100%;
    background-color: #FFF;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 146px);
}

.section {
    background-color: transparent;
}

.main-section {
    padding: 0px 80px;

    @media (max-width: 992px) {
        padding: 0px 40px;
    }
}

.institution-card {
    max-width: 31%;
}

.recommendations {
    @media (max-width: 1090px) and (min-width: 992px) {
        width: 100%;
    }
}

.special-of-the-day {
    padding: 0px 15px;
}

.special-of-the-day-row {
    @media (max-width: 992px) {
        padding-top: 20px;
        width: 100%;
    }
}


.container {

    @media (max-width: 992px) {
        max-width: 100%;
        padding: 0px 40px;
    }


    @media (max-width: 500px) {
        padding: 0px 20px;
    }

}

.in-my-area-map {
    @media (max-width: 560px) {
        min-height: 400px !important;
    }

    @media (max-width: 480px) {
        min-height: 350px !important;
    }
}


.scroll-container {

    @media (max-width: 992px) {
        flex-wrap: wrap;
    }

    @media (max-width: 731px) {
        flex-wrap: nowrap;
    }
}

.category-row {
    @media (max-width: 731px) {
        padding-top: 10px;
    }
}

.footer-fix {
    background-color: #FFF;
}

@import '@/scss/_variables.scss';

.banner {
    padding-top: 150px;
    text-align: center;
    position: relative;
    margin-bottom: 50px;
    padding-left: 60px;

    @media(max-width: 991px) {
        margin-bottom: 30px;
        padding-top: 100px;
        padding-left: 0;
    }
}



.banner-container {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
}

.bg-image {
    background-image: url('/assets/logos/bg_img.png');
    background-position: 60vw -5vh;
    background-repeat: no-repeat;
    background-size: 1000px 1000px;
    height: 100%;
    min-height: calc(100vh - 146px);

    @media(max-width: 1500px) {
        background-position: 50vw -5vh;
    }

    @media(max-width: 1200px) {
        background-position: 40vw -10vh;
    }

    @media(max-width:991px) {
        background-size: 500px, 500px;
        background-position: 50vw 0vh;
    }

    @media(max-width: 550px) {
        background-position: 30vw 0vh;
        min-height: calc(100vh - 278px);
    }
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin: 0; // Reset default row margin

    .cat {
        flex: 0 0 auto;
        width: calc(25% - 18px); // 4 cards per row with gap
        padding: 0; // Remove default column padding
        margin: 0; // Remove default column margin

        @media (max-width: 992px) {
            width: calc(50% - 12px); // 2 cards per row on tablet
        }

        @media (max-width: 450px) {
            width: 100%; // Single column on mobile
            max-width: 100%;
        }
    }

    .category-card {
        width: 100%;
        height: 100%;
    }
}

// For subsections (EventSection and KulTourSection)
.section {
    .scroll-container {
        display: flex;
        gap: 24px;
        padding: 0;
        margin: 0;
        overflow-x: auto;

        // Hide scrollbar but keep functionality
        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;

        .card-wrapper {
            flex: 0 0 auto;
            width: calc(25% - 18px); // Match the top cards width
            min-width: 280px; // Minimum width to prevent too narrow cards

            @media (max-width: 992px) {
                width: calc(50% - 12px);
            }

            @media (max-width: 731px) {
                width: 85%; // Slightly wider on mobile for better visibility
            }
        }
    }
}
</style>
<style lang="scss">
.kultour-home-alt .banner-content {
    text-align: left;

    .banner-text {
        font-size: 72px;
        font-family: elliot-regular;
        font-weight: 300;

        @media(max-width:1255px) {
            font-size: 68px;
        }

        @media(max-width: 991px) {
            font-size: 64px;
        }

        @media(max-width: 460px) {
            font-size: 54px;
        }

        @media(max-width: 381px) {
            font-size: 44px;
        }


        &-bold {
            font-family: elliot-bold;
            font-weight: 500;
        }
    }

    .banner-text-description,
    .banner-text-description * {
        font-size: 20px !important;
        line-height: 32px !important;
        color: #000 !important;

        @media(max-width: 1200px) {
            font-size: 18px !important;
        }

        @media(max-width: 1100px) {
            font-size: 17px !important;
        }

        @media(max-width: 991px) {
            display: none !important;
        }
    }
}
</style>