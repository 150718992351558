<template>
    <div class="card">
        <div class="card-body">

            <div class="heading">
                <i class="material-icons" aria-hidden="true">headphones</i>
                <div class="text">
                    <p class="header">{{ audioTitle }}</p>
                    <span v-html="audioDescription"></span>
                </div>
            </div>

            <div id="audio" class="player-wrapper">
                <audio-player :file="audioPath"></audio-player>
            </div>

        </div>
    </div>
</template>

<script>
import { getFieldValues, json_decode } from '@/utils/helpers';

export default {
    name: "AudioCard",
    components: {
        AudioPlayer: () => import('@/components/controls/AudioPlayer.vue'),
    },
    props: {
        audioWalk: {
            type: [Object, Array],
            required: true,
        }
    },
    data() {
        return {
            maxLength: 300,
            showMore: false,
            audio: null,
        }
    },
    computed: {
        audioPath() {
            var audiofile = getFieldValues(this.audioWalk, 'audio-datei');
            if (audiofile !== null) {
                audiofile = json_decode(audiofile);
                return this.$backendUrl + audiofile.path;
            }
            return '/assets/audio/audio.mp3';
        },
        audioTitle() {
            var title = getFieldValues(this.audioWalk, 'title');
            if (title !== null) {
                return this.title = getFieldValues(this.audioWalk, 'title');
            }
            return "Mit Audio";
        },
        audioDescription() {
            var description = getFieldValues(this.audioWalk, 'body');
            if (description !== null) {
                return this.description = getFieldValues(this.audioWalk, 'body');
            }
            return "Für diese KulTour gibt es eine Audioaufnahme. Starte die Tour mit dem Play-Button."
        },
        btnShowMore() {
            if (this.audioDescription.length > this.maxLength) {
                return true;
            }
            return false;
        },
    },
    methods: {
        play() {
            var audio = new Audio('/assets/audio/audio.mp3');
            this.audio = audio;
            audio.play();
        },
        pause() {
            console.log(this.audio);
            this.audio.pause();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.card {
    position: relative;
    border-radius: 5px;
    border-bottom-left-radius: 30px;
    background: #fff;
    border: none;
    padding: 40px;
    box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);

    #audio {
        margin-top: 20px;
    }

    &-body {
        padding: 0;
        margin: 0px 0px 15px 0;

        p {
            line-height: 24px;
            font-size: 14px;
        }

        .heading {
            display: flex;
            align-items: center;

            .text {
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
            }

            .header {
                font-size: 16px;
                font-family: 'elliot-bold', sans-serif;
                font-weight: 700;
            }

            i.material-icons {
                font-size: 50px;
                vertical-align: middle;
            }
        }

    }

    &-buttons {
        button.btn {
            cursor: auto;
            margin-right: 10px;
            margin-bottom: 10px;

            @media (max-width: 768px) {
                margin-bottom: 10px
            }
        }
    }

    @media (max-width: 500px) {
        padding: 20px;
    }
}

.card:first-child {
    margin-top: 0;
}
</style>
