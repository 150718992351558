<template>
    <div class="card card-event" :title="linkToTitle" :alt="linkToTitle"
        :style="{ marginBottom: marginBottom, marginRight: marginRight }">
        <router-link :to="{ name: 'Veranstaltung', params: { id: content.id } }" :title="linkToTitle"
            :alt="linkToTitle">
            <div class="card-heading" :style="teaserImageStyle">
                <div>
                    <div class="card-heading-title">
                        <span>{{ categories }}</span>
                        <h3>{{ title }}</h3>
                    </div>
                </div>

                <button :title="linkToMerklisteOptions" :alt="linkToMerklisteOptions" class="btn btn-link btn-favorite"
                    :class="isInCollectionClass" @click="triggerAddToCollectionDialog">
                    <i class="material-icons" aria-hidden="true">anchor</i>
                </button>

            </div>

            <div class="card-body d-flex">
                <div class="event-date now" v-if="isCurrentlyActive">
                    <span>Jetzt geöffnet</span>
                </div>
                <div class="event-date"
                    v-else-if="nextAppointment != null && nextAppointmentWday != null && nextAppointmentDate == null">
                    <span>{{ nextAppointmentWday }}.</span>
                </div>
                <div class="event-date"
                    v-else-if="nextAppointment != null && nextAppointmentDate != null && nextAppointmentMonthAndYear != null">
                    <span>{{ nextAppointmentDate }}.</span>
                    <span class="next">{{ nextAppointmentMonthAndYear }}</span>
                </div>
                <div class="event">
                    <div class="event-time mb-10"
                        v-if="nextAppointment != null && nextAppointmentStartTime != null && nextAppointmentToTime != null && !isCurrentlyActive">
                        <i class="material-icons material-icons-round" aria-hidden="true">access_time</i>
                        <span>{{ nextAppointmentStartTime }} - {{ nextAppointmentToTime }}</span>
                    </div>
                    <div class="event-venue d-flex" v-if="institution != null">
                        <i class="material-icons material-icons-round" aria-hidden="true">person</i>
                        <span class="institution-title">{{ institution }}</span>
                    </div>
                    <div class="address" v-if="adresse != null">
                        <i class="material-icons material-icons-round" aria-hidden="true">location_on</i>
                        <span>{{ adresse }}</span>
                    </div>
                </div>


            </div>


        </router-link>

        <AddContentToCollection :content="content" v-if="showAddToCollectionDialog"
            @closeModal="showAddToCollectionDialog = false" @reloadCollection="emitReloadCollection">
        </AddContentToCollection>

    </div>
</template>

<script>
import AddContentToCollection from '@/components/modal/addContentToCollection/dialog.vue';
import { getFieldValues, getPlaceholderImage, getNextAppointment, isAppointmentNow, getDateFromDateParam, formatDate, formatTime, getWeekday, getMonth, isOneOfTheAppointmentsNow, getCurrentSystemDate } from '@/utils/helpers';

export default {
    name: "CardEvent",
    components: {
        AddContentToCollection
    },
    props: {
        institutionProp: {
            type: Object,
            default: null,
        },
        content: {
            type: Object,
            required: true
        },
        date: {
            type: Date,
            default: null,
        },
        marginBottom: {
            type: String,
            default: '30px'
        },
        marginRight: {
            type: String,
            default: '15px'
        }
    },
    data() {
        return {
            showAddToCollectionDialog: false,
        };
    },
    computed: {
        teaserImageStyle() {
            return 'max-height: ' + this.imageHeight + '; backgroundImage: url("' + this.teaserImage + '")';
        },
        linkToTitle() {
            return "Mehr Informationen zu: " + this.content.title;
        },
        linkToMerklisteOptions() {
            return "Optionen zum Merken für: " + this.content.title;
        },
        eventDay: function () {
            return "";
        },
        isLoggedIn() {
            if (this.$store.getters.getToken) {
                return true;
            }
            return false;
        },
        collections() {
            return this.$store.getters.getCollections;
        },
        isInCollectionClass() {
            if (this.isLoggedIn && this.isContentInCollection()) {
                return 'active';
            }
            return '';
        },
        eventMonth: function () {
            return "";
        },
        title() {
            if (this.content != null) {
                var title = this.content.title;
                title = title.length > 65 ? title.substring(0, 65) + ' ...' : title;
                return title;
            }
            return "";
        },
        teaserImages() {
            var pictures = getFieldValues(this.content, 'teaser-bild');
            if (pictures != null) {
                if (Array.isArray(pictures)) {
                    return pictures;
                }
                else {
                    return [pictures];
                }
            }
            return null;
        },
        teaserImage() {
            var images = this.teaserImages;
            var img = null;
            if (images != null && Array.isArray(images) && images.length > 0) {
                img = images[Math.floor(Math.random() * images.length)];
            }
            else if (images != null) {
                img = images;
            }

            if (img != null && img.includes('https')) {
                return img;
            }
            else {
                return this.$backendUrl + img;
            }
            /*we'll need an alternative if there is no image*/
            return this.getPlaceholderImage(this.category);

        },
        addressObj() {
            return getFieldValues(this.content, 'adresse');
        },
        adresse() {
            if (this.addressObj != null) {
                return this.addressObj.street + ', ' + this.addressObj.zipcode + ' ' + this.addressObj.city;
            }
            return '';
        },
        categories() {
            /*get the categories from this content*/
            var cats = getFieldValues(this.content, 'kultur_kategorien');
            /*check if there is a value in the cats*/
            if (cats != null && cats.length > 0) {
                /*is cats an array?*/
                if (Array.isArray(cats)) {
                    return cats[0];
                }
                else {
                    return cats;
                }
            }
            return null;
        },
        isDateToday() {
            if (this.date != null) {
                if (getDateFromDateParam(this.date) == getCurrentSystemDate()) {
                    return true;
                }
            }
            return false;
        },
        appointments() {
            return getFieldValues(this.content, 'appointments');
        },
        institution() {
            if (Object.prototype.hasOwnProperty.call(this.content, 'institution_title') && this.content.institution_title != null) {
                var title = this.content.institution_title;
                title = title.length > 30 ? title.substring(0, 30) + ' ...' : title;
                return title;
            }
            return null;
        },
        nextAppointment() {
            if (this.date != null) {
                return getNextAppointment(this.appointments, this.date);
            }
            return getNextAppointment(this.appointments);
        },
        nextAppointmentWday() {
            if (this.nextAppointment != null && this.nextAppointment.wday != null) {
                var day = getWeekday(this.nextAppointment.wday);
                return day.substring(0, 2);
            }
            return null;
        },
        nextAppointmentDate() {
            if (this.nextAppointment != null && this.nextAppointment.date != null) {
                var date = new Date(this.nextAppointment.date);
                return date.getDate();
            }
            return null;
        },
        nextAppointmentStartTime() {
            if (this.nextAppointment != null && this.nextAppointment.time != null && this.nextAppointment.time.time_from != null) {
                var time = this.nextAppointment.time.time_from;
                return formatTime(time);
            }
            return null;
        },
        nextAppointmentToTime() {
            if (this.nextAppointment != null && this.nextAppointment.time != null && this.nextAppointment.time.time_to != null) {
                var time = this.nextAppointment.time.time_to;
                return formatTime(time);
            }
            return null;
        },
        nextAppointmentMonthAndYear() {
            if (this.nextAppointment != null && this.nextAppointment.date != null) {
                var date = new Date(this.nextAppointment.date);
                var month = getMonth(date.getMonth() + 1);
                var year = date.getFullYear();
                return month.substring(0, 3) + '. ' + year;
            }
            return null;
        },
        isCurrentlyActive() {
            /*only look if one of the appointments is now if there is no date given as a param*/
            if (this.appointments != null && this.isDateToday) {
                return isOneOfTheAppointmentsNow(this.appointments);
            }
            return false;
        },
    },
    methods: {
        formatDate,
        formatTime,
        getMonth,
        getWeekday,
        getCurrentSystemDate,
        getDateFromDateParam,
        isAppointmentNow,
        getNextAppointment,
        isOneOfTheAppointmentsNow,
        getPlaceholderImage,
        emitReloadCollection(collection_id) {
            this.$emit('reloadCollection', collection_id);
        },
        triggerAddToCollectionDialog(event) {
            event.preventDefault();
            this.showAddToCollectionDialog = true;
        },
        isContentInCollection() {
            if (this.collections != null) {
                for (var prop in this.collections) {
                    var collectionGroup = this.collections[prop];
                    for (var i = 0; i < collectionGroup.length; i++) {
                        var currentCollection = collectionGroup[i];
                        if (Object.prototype.hasOwnProperty.call(currentCollection, 'contents') && currentCollection.contents != null) {
                            if (currentCollection != null && currentCollection.contents.length > 0) {
                                for (var j = 0; j < currentCollection.contents.length; j++) {
                                    if (currentCollection.contents[j].id == this.content.id) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return false;
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.card {
    position: relative;
    border-radius: 5px;
    border-bottom-left-radius: 30px;
    min-width: 215px;
    border: none;
    box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .btn.btn-favorite {
        top: 2px;
        bottom: auto !important;
    }

    &:hover,
    &:focus,
    &:active {
        color: $primary;
        box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.35);
    }

    &-heading {
        background-position: center;
        background-size: cover;
        background: $primary;
        padding: 15px;
        border-radius: 5px;
        color: #fff;
        min-height: 125px;
        line-height: normal;
        position: relative;

        &-title {
            width: calc(100% - 100px);
            background-color: rgba(0, 24, 62, 0.63);
            padding: 10px;
            border-radius: 5px;
            position: absolute;
            bottom: 15px;
            left: 15px;
        }

        span {
            font-size: 10px;
            text-transform: uppercase;
            display: block;
        }

        h3 {
            color: #fff;
            margin-top: 10px;
            font-size: 14px;
        }
    }

    &-body {
        padding: 15px;
        display: inline-flex;
        vertical-align: middle;
        align-items: center;

        .event-date {
            width: 100px;

            span {
                display: block;
                font-size: 24px;
                color: #00183e;
                line-height: 1.25;
                font-family: elliot-bold, sans-serif;
                font-weight: 700;
                margin-bottom: 0;
            }

            span.next {
                display: block;
                font-size: 12px;
                color: #00183e;
                line-height: 1.25;
                font-family: elliot-bold, sans-serif;
                font-weight: 700;
                margin-bottom: 0;
            }
        }

        .event-date.now span {
            font-size: 16px;
            line-height: 1.25;
            font-family: elliot-bold, sans-serif;
            font-weight: 700;
            margin-bottom: 0;
            color: #1BA818 !important;
        }

        .event {

            &-time,
            &-venu {
                display: inline-flex;
                vertical-align: middle;
                align-items: center;
            }

            &-time {
                margin-bottom: 10px;

                span {
                    line-height: 1px;
                }
            }

            i.material-icons {
                font-size: 15px;
                margin-right: 10px;
            }

            span {
                display: inline-block;
                line-height: 14px;
                font-size: 12px;
            }

            span.institution-title {
                word-break: break-word;
            }

            .address {
                margin-top: 10px;
                display: inline-flex;
                vertical-align: middle;
                align-items: center;
            }
        }
    }
}
</style>
