<template>
    <div class="gallery-component">
        <!-- Slider für mehrere Bilder -->
        <div v-if="sliderImages && sliderImages.length > 1" class="splide-carousel slider">
            <splide :options="sliderOptions" ref="teaserCarousel" class="full-width">
                <splide-slide v-for="(slide, index) in sliderImages" :key="index" :aria-label="slide.description"
                    class="slide full-width">
                    <div tabindex="-1" class="full-width profile-image-placeholder" :class="slide.class"
                        :style="getBackgroundImage(slide.image)" @click="showLightbox(index)">
                    </div>
                    <span v-if="slide.copyright" class="copyright">&copy; {{ slide.copyright }}</span>
                </splide-slide>
            </splide>
        </div>

        <!-- Einzelnes Bild -->
        <div v-else-if="sliderImages && sliderImages.length === 1" style="margin-top: 10px;" @click="showLightbox(0)">
            <img :src="sliderImages[0].image" :alt="sliderImages[0].description"
                :style="{ height: sliderOptions.height }" />
            <span v-if="sliderImages[0].copyright" class="copyright">&copy; {{ sliderImages[0].copyright }}</span>
        </div>

        <!-- Lightbox -->
        <vue-easy-lightbox :visible="lightBoxVisible" :imgs="imgs" :index="lightBoxIndex"
            @hide="hideLightbox"></vue-easy-lightbox>
    </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import VueEasyLightbox from 'vue-easy-lightbox';
import '@splidejs/splide/dist/css/splide.min.css';

export default {
    name: 'GalleryComponent',

    components: {
        Splide,
        SplideSlide,
        VueEasyLightbox
    },

    props: {
        sliderImages: {
            type: Array,
            default: () => []
        },
    },

    data() {
        return {
            sliderOptions: {
                type: 'loop',
                gap: 20,
                perPage: 3,
                breakpoints: {
                    640: {
                        perPage: 1,
                    },
                },
                perMove: 1,
                rewind: true,
                height: '10rem',
                focus: 'center',
                pagination: false
            },
            lightBoxVisible: false,
            lightBoxIndex: 0
        }
    },

    computed: {
        imgs() {
            return this.sliderImages ? this.sliderImages.map(img => img.image) : []
        }
    },

    methods: {
        getBackgroundImage(image) {
            return {
                backgroundImage: `url(${image})`
            }
        },

        showLightbox(index) {
            this.lightBoxIndex = index
            this.lightBoxVisible = true
        },

        hideLightbox() {
            this.lightBoxVisible = false
        }
    }
}
</script>

<style lang="scss">
.gallery-component {
    width: 100%;
    position: relative;


    .profile-image-placeholder {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .copyright {
        position: absolute;
        bottom: 8px;
        right: 8px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 4px 8px;
        font-size: 12px;
        border-radius: 4px;
    }

    .single-image {
        position: relative;
        cursor: pointer;
    }

    .splide-carousel {
        cursor: pointer;
    }

    .splide {
        position: relative;
        width: calc(100% - 100px);
        margin: 0 auto;
    }

    .splide__arrow--prev {
        position: absolute;
        left: -50px;
        top: 50%;
        transform: translateY(-50%);
    }

    .splide__arrow--next {
        position: absolute;
        right: -50px;
        top: 50%;
        transform: translateY(-50%);
    }

}
</style>
